import Vue from 'vue'
import VueRouter from 'vue-router'
import useJwt from '@/auth/jwt/useJwt'
import { TEXT_ELLIPSIS } from '@/constants/app'

Vue.use(VueRouter)

const advancedSearchData = {
  component: () => import('@/views/pages/AdvancedSearch/Form.vue'),
  meta: {
    pageTitle: 'Advanced Search',
    breadcrumb: [
      {
        text: 'Advanced Search',
        active: true,
      },
    ],
  },
}

const formData = {
  component: () => import('@/views/pages/Forms/Form.vue'),
  meta: {
    pageTitle: 'Forms',
    breadcrumb: [
      {
        text: 'Forms',
        active: true,
      },
    ],
  },
}
const contractTemplateData = {
  component: () => import('@/views/pages/ContractTemplates/Form.vue'),
  meta: {
    pageTitle: 'Document Template',
    breadcrumb: [
      {
        text: 'Document Template',
        active: true,
      },
    ],
  },
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    {
      path: '/sales-and-commissions/commissions',
      name: 'commissions',
      parent: 'sales-and-commissions',
      component: () => import('@/views/pages/Commissions/View.vue'),
      meta: {
        hideNavbar: true,
        pageTitle: 'Commissions',
        breadcrumb: [
          {
            text: 'Sales & Commissions',
            active: false,
          },
        ],
      },
    },
    {
      path: '/sales-and-commissions/sales/:id?/:tableName?',
      name: 'sales',
      parent: 'sales-and-commissions',
      component: () => import('@/views/pages/Sales/View.vue'),
      meta: {
        pageTitle: 'Sales',
        breadcrumb: [
          {
            text: 'Sales & Commissions',
            active: false,
          },
        ],
      },
    },
    {
      path: '/sales-and-commissions/sales/:id?/:tableName?/contacts/',
      name: 'sales-contacts',
      component: () => import('@/views/pages/Sales/Contacts/View.vue'),
      meta: {
        pageTitle: 'Contacts',
        breadcrumb: [
          {
            name: 'sales',
            text: 'Sales',
            active: false,
            to: '/sales-and-commissions/sales/:id?/:tableName?',
          },
        ],
      },
    },
    {
      path: '/sales-and-commissions/sales/:id?/:tableName?/contacts/:contactId',
      name: 'seller',
      component: () => import('@/views/pages/Sales/Seller/SalesView.vue'),
      props: true,
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            name: 'sales',
            text: 'Sales',
            active: false,
            to: '/sales-and-commissions/sales/:id?/:tableName?',
          },
        ],
      },
    },
    {
      path: '/',
      name: 'defaultPage',
      component: () => import('@/views/pages/DefaultPage.vue'),
      meta: {
        pageTitle: 'Loading...',
        breadcrumb: [],
      },
    },
    {
      path: '/dashboard/:id',
      name: 'dashboard',
      component: () => import('@/views/pages/Dashboard.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clrgen',
      name: 'clrgen',
      component: () => import('@/views/pages/ColorGenerator.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/companies',
      name: 'companies',
      component: () => import('@/views/pages/Companies/List.vue'),
      meta: {
        pageTitle: 'Companies',
        breadcrumb: [
          {
            text: 'Companies',
            active: true,
            baseList: null,
          },
        ],
      },
    },
    {
      path: '/companies/:id/:tab?/:idFeed?',
      name: 'company-view',
      component: () => import('@/views/pages/Companies/View.vue'),
      props: true,
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Companies',
            active: true,
            baseTab: {
              text: 'Companies',
              path: '/companies',
            },
          },
        ],
      },
    },
    {
      path: '/documents/:id/:tab?',
      name: 'document-view',
      component: () => import('@/views/pages/Documents/View.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Documents',
            active: true,
            baseTab: {
              text: 'Documents',
              path: '/documents',
            },
            sourceViews: [
              'company-view',
            ],
          },
        ],
      },
    },
    {
      path: '/documents',
      name: 'documents',
      component: () => import('@/views/pages/Documents/List.vue'),
      meta: {
        pageTitle: 'Documents',
        breadcrumb: [
          {
            text: 'Documents',
            active: true,
          },
        ],
      },
    },
    {
      path: '/inquiries/:id/:tab?',
      name: 'inquiry-view',
      component: () => import('../views/pages/Inquiries/View.vue'),
      meta: {
        pageTitle: 'Inquiries',
        breadcrumb: [
          {
            text: 'Inquiries',
            active: true,
            baseTab: {
              text: 'Inquiries',
              path: '/inquiries',
            },
            sourceViews: [
              'company-view',
            ],
          },
        ],
      },
    },
    {
      path: '/inquiries',
      name: 'inquiries',
      component: () => import('../views/pages/Inquiries/List.vue'),
      meta: {
        pageTitle: 'Inquiries',
        breadcrumb: [
          {
            text: 'Inquiries',
            active: true,
          },
        ],
      },
    },
    {
      path: '/todo/:id/:tab?',
      name: 'todo-view',
      component: () => import('../views/pages/Todo/View.vue'),
      meta: {
        pageTitle: 'To Do',
        breadcrumb: [
          {
            text: 'To Do',
            active: true,
            baseTab: {
              text: 'To Do',
              path: '/todo',
            },
            sourceViews: [
              'company-view',
            ],
          },
        ],
      },
    },
    {
      path: '/todo',
      name: 'todo',
      component: () => import('../views/pages/Todo/List.vue'),
      meta: {
        pageTitle: 'To Do',
        breadcrumb: [
          {
            text: 'To Do',
            active: true,
          },
        ],
      },
    },
    {
      path: '/reports',
      name: 'reports.index',
      component: () => import('@/views/pages/Reports/ReportList.vue'),
      meta: {
        pageTitle: 'Reports',
        breadcrumb: [
          {
            text: 'Reports',
            active: true,
          },
        ],
      },
    },
    {
      path: '/reports/:id',
      name: 'reports.generate',
      component: () => import('@/views/pages/Reports/ReportGenerate.vue'),
      meta: {
        pageTitle: TEXT_ELLIPSIS,
        breadcrumb: [
          {
            text: 'Reports',
            active: true,
            baseTab: {
              text: 'Reports',
              path: '/reports',
            },
          },
        ],
      },
    },
    {
      path: '/notes/:id/:tab?',
      name: 'note-view',
      component: () => import('@/views/pages/Notes/View.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Notes',
            active: true,
            baseTab: {
              text: 'Notes',
              path: '/notes',
            },
            sourceViews: [
              'company-view',
            ],
          },
        ],
      },
    },
    {
      path: '/notes',
      name: 'notes',
      component: () => import('@/views/pages/Notes/List.vue'),
      meta: {
        pageTitle: 'Notes',
        breadcrumb: [
          {
            text: 'Notes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: () => import('@/views/pages/Contacts/List.vue'),
      meta: {
        pageTitle: 'Contacts',
        breadcrumb: [
          {
            text: 'Contacts',
            active: true,
          },
        ],
      },
    },
    {
      path: '/contacts/:id/:tab?/:idFeed?',
      name: 'contact-view',
      component: () => import('@/views/pages/Contacts/View.vue'),
      props: true,
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Contacts',
            active: true,
            baseTab: {
              text: 'Contacts',
              path: '/contacts',
            },
          },
        ],
      },
    },
    {
      path: '/users/:id',
      name: 'user-view',
      component: () => import('@/views/pages/SystemSettings/Users/View.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },

    {
      path: '/custom-entity/:key/:id/:tab?',
      name: 'custom-entity-data-view',
      component: () => import('@/views/pages/CustomEntity/View/index.vue'),
      meta: {
        pageTitle: TEXT_ELLIPSIS,
        breadcrumb: [
          {
            text: TEXT_ELLIPSIS,
            active: true,
            baseTab: {
              path: '/custom-entity',
            },
            sourceViews: [
              'company-view',
            ],
          },
        ],
      },
    },
    {
      path: '/custom-entity/:key',
      name: 'custom-entity',
      component: () => import('@/views/pages/CustomEntity/List/index.vue'),
      meta: {
        pageTitle: TEXT_ELLIPSIS,
        breadcrumb: [
          {
            text: TEXT_ELLIPSIS,
            active: true,
          },
        ],
      },
    },
    {
      path: '/advanced-search',
      name: 'advanced-search',
      component: () => import('../views/pages/AdvancedSearch/List.vue'),
      meta: {
        pageTitle: 'Advanced Search',
        breadcrumb: [
          {
            text: 'Advanced Search',
            active: true,
          },
        ],
      },
    },
    {
      path: '/forms',
      name: 'forms',
      component: () => import('@/views/pages/Forms/List.vue'),
      meta: {
        pageTitle: 'Forms',
        breadcrumb: [
          {
            text: 'Forms',
            active: true,
          },
        ],
      },
    },
    {
      path: '/forms/create',
      name: 'forms.create',
      ...formData,
    },
    {
      path: '/forms/:id/submissions',
      name: 'forms.submissions',
      component: () => import('@/views/pages/Forms/Submissions.vue'),
      meta: {
        pageTitle: 'Forms',
        breadcrumb: [
          {
            text: 'Forms',
            active: true,
          },
        ],
      },
    },
    {
      path: '/forms/:id/edit',
      name: 'form-view.edit',
      ...formData,
    },
    {
      path: '/forms/:id/view',
      name: 'form-view.view',
      ...formData,
    },
    {
      path: '/document-template',
      name: 'document-template',
      component: () => import('@/views/pages/ContractTemplates/List.vue'),
      meta: {
        pageTitle: 'Document Templates',
        breadcrumb: [
          {
            text: 'Document Templates',
            active: true,
          },
        ],
      },
    },
    {
      path: '/document-template/create',
      name: 'document-template.create',
      ...contractTemplateData,
    },
    {
      path: '/document-template/view/:id',
      name: 'document-template.view',
      ...contractTemplateData,
    },
    {
      path: '/advanced-search/create',
      name: 'advanced-search.create',
      ...advancedSearchData,
    },
    {
      path: '/advanced-search/:queryType/:id/edit',
      name: 'advanced-search-view.edit',
      ...advancedSearchData,
    },
    {
      path: '/advanced-search/:queryType/:id/:action',
      name: 'advanced-search-view.config.view',
      ...advancedSearchData,
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/pages/Profile/Index.vue'),
      meta: {
        pageTitle: 'Account Settings',
        breadcrumb: [
          {
            text: 'Account Settings',
            active: true,
          },
        ],
      },

      children: [
        {
          path: 'user-profile',
          name: 'user-profile',
          component: () => import('@/views/pages/Profile/UserProfile.vue'),
          meta: {
            pageTitle: 'Account Settings',
            breadcrumb: [
              {
                text: 'User Profile',
                active: true,
              },
            ],
          },
        },

        {
          path: 'change-password',
          name: 'change-password',
          component: () => import('@/views/pages/Profile/ChangePassword.vue'),
          meta: {
            pageTitle: 'Account Settings',
            breadcrumb: [
              {
                text: 'Change Password',
                active: true,
              },
            ],
          },
        },

        {
          path: 'information',
          name: 'information',
          component: () => import('@/views/pages/Profile/Information.vue'),
          meta: {
            pageTitle: 'Account Settings',
            breadcrumb: [
              {
                text: 'Information',
                active: true,
              },
            ],
          },
        },

        {
          path: 'notifications',
          name: 'notifications',
          component: () => import('@/views/pages/Profile/ProfileNotification.vue'),
          meta: {
            pageTitle: 'Account Settings',
            breadcrumb: [
              {
                text: 'Notifications',
                active: true,
              },
            ],
          },
        },

        {
          path: 'language',
          name: 'language',
          component: () => import('@/views/pages/Profile/ProfileLanguage.vue'),
          meta: {
            pageTitle: 'Language',
            breadcrumb: [
              {
                text: 'Language',
                active: true,
              },
            ],
          },
        },
      ],
    },
    {
      path: '/system-settings',
      name: 'system-settings',
      component: () => import('@/views/pages/SystemSettings/SystemSettings.vue'),
      meta: {
        pageTitle: 'System Settings',
        breadcrumb: [
          {
            text: 'System Settings',
            active: true,
          },
        ],
      },
      children: [
        {
          path: 'company-settings',
          name: 'company-settings',
          component: () => import('@/views/pages/SystemSettings/CompanySettings.vue'),
          meta: {
            pageTitle: 'System Settings',
            breadcrumb: [
              {
                text: 'Company Settings',
                active: true,
              },
            ],
          },
        },
        {
          path: 'users',
          name: 'users-settings',
          component: () => import('@/views/pages/SystemSettings/Users/List.vue'),
          meta: {
            pageTitle: 'System Settings',
            breadcrumb: [
              {
                text: 'Users',
                active: true,
              },
            ],
          },
        },
        {
          path: 'groups',
          name: 'groups-settings',
          component: () => import('@/views/pages/SystemSettings/Groups/List.vue'),
          meta: {
            pageTitle: 'System Settings',
            breadcrumb: [
              {
                text: 'Groups',
                active: true,
              },
            ],
          },
        },
        {
          path: 'roles',
          name: 'roles-settings',
          component: () => import('@/views/pages/SystemSettings/RolesAndPermissions/List.vue'),
          meta: {
            pageTitle: 'System Settings',
            breadcrumb: [
              {
                text: 'Roles And Permissions',
                active: true,
              },
            ],
          },
        },
        {
          path: 'custom-fields',
          name: 'custom-field-settings',
          component: () => import('@/views/pages/SystemSettings/CustomFields/List.vue'),
          meta: {
            pageTitle: 'System Settings',
            breadcrumb: [
              {
                text: 'Custom Fields',
                active: true,
              },
            ],
          },
        },
        {
          path: 'custom-entities',
          name: 'custom-entity-settings',
          component: () => import('@/views/pages/SystemSettings/CustomEntities/List.vue'),
          meta: {
            pageTitle: 'System Settings',
            breadcrumb: [
              {
                text: 'Custom Entities',
                active: true,
              },
            ],
          },
        },
        {
          path: 'inquiries',
          name: 'inquiries-settings',
          component: () => import('@/views/pages/SystemSettings/Inquiries/index.vue'),
          meta: {
            pageTitle: 'System Settings',
            breadcrumb: [
              {
                text: 'Inquiries',
                active: true,
              },
            ],
          },
        },
        {
          path: 'todo',
          name: 'todo-settings',
          component: () => import('@/views/pages/SystemSettings/Todo/index.vue'),
          meta: {
            pageTitle: 'System Settings',
            breadcrumb: [
              {
                text: 'To Do',
                active: true,
              },
            ],
          },
        },
        {
          path: 'statuses',
          name: 'status-settings',
          component: () => import('@/views/pages/SystemSettings/Statuses/List.vue'),
          meta: {
            pageTitle: 'System Settings',
            breadcrumb: [
              {
                text: 'Statuses',
                active: true,
              },
            ],
          },
        },
        {
          path: 'priorities',
          name: 'priority-settings',
          component: () => import('@/views/pages/SystemSettings/Priorities/List.vue'),
          meta: {
            pageTitle: 'System Settings',
            breadcrumb: [
              {
                text: 'Priorities',
                active: true,
              },
            ],
          },
        },
        {
          path: 'categories',
          name: 'category-settings',
          component: () => import('@/views/pages/SystemSettings/Categories/List.vue'),
          meta: {
            pageTitle: 'System Settings',
            breadcrumb: [
              {
                text: 'Categories',
                active: true,
              },
            ],
          },
        },
        {
          path: 'sources',
          name: 'source-settings',
          component: () => import('@/views/pages/SystemSettings/Sources/List.vue'),
          meta: {
            pageTitle: 'System Settings',
            breadcrumb: [
              {
                text: 'Sources',
                active: true,
              },
            ],
          },
        },
        {
          path: 'integrations',
          name: 'integrations-settings',
          component: () => import('@/views/pages/SystemSettings/Integrations/index.vue'),
          meta: {
            pageTitle: 'Integrations Settings',
            breadcrumb: [
              {
                text: 'Integrations',
                active: true,
              },
            ],
          },
        },
        {
          path: 'dashboard-widget/:tab?',
          name: 'dashboard-widget',
          component: () => import('@/views/pages/SystemSettings/DashboardWidgets/index.vue'),
          meta: {
            pageTitle: 'System Settings',
            breadcrumb: [
              {
                text: 'Dashboard and Widgets',
                active: true,
              },
            ],
          },
        },
        {
          path: 'expanded-entity-overview-widgets/:tab?',
          name: 'expanded-entity-overview-settings',
          component: () => import('@/views/pages/SystemSettings/ExpandedEntityOverview/Widgets.vue'),
          meta: {
            pageTitle: 'Expanded Entity Overview Widgets',
            breadcrumb: [
              {
                text: 'Expanded Entity Overview Widgets',
                active: true,
              },
            ],
          },
        },
        {
          path: 'crm-relationships/:tab?',
          name: 'crm-relationship-settings',
          component: () => import('@/views/pages/SystemSettings/CrmRelationships/index.vue'),
          meta: {
            pageTitle: 'CRM Relationships',
            breadcrumb: [
              {
                text: 'CRM Relationships',
                active: true,
              },
            ],
          },
        },
        {
          path: 'field-management-settings/:tab?',
          name: 'field-management-settings',
          component: () => import('@/views/pages/FieldManagement/FieldManagement.vue'),
          meta: {
            pageTitle: 'Field Management',
            breadcrumb: [
              {
                text: 'Field Management',
                active: true,
              },
            ],
          },
        },
        {
          path: 'digital-signature',
          name: 'digital-signature-settings',
          component: () => import('@/views/pages/SystemSettings/DigitalSignature/SignicatSettings.vue'),
          meta: {
            pageTitle: 'System Settings',
            breadcrumb: [
              {
                text: 'Digital Signature',
                active: true,
              },
            ],
          },
        },
        {
          path: 'shortcut',
          name: 'shortcuts-settings',
          component: () => import('@/views/pages/SystemSettings/Shortcut/List.vue'),
          meta: {
            pageTitle: 'System Settings',
            breadcrumb: [
              {
                text: 'Shortcuts',
                active: true,
              },
            ],
          },
        },
        {
          path: 'commission-models',
          name: 'commission-models',
          component: () => import('@/views/pages/SystemSettings/CommissionModels/View.vue'),
          meta: {
            pageTitle: 'System Settings',
            breadcrumb: [
              {
                text: 'Commission Models',
                active: true,
              },
            ],
          },
          children: [
            {
              path: '/model/:id',
              name: 'model',
              component: () => import('@/views/pages/SystemSettings/CommissionModels/ModelDetails.vue'),
              meta: {
                pageTitle: '',
                breadcrumb: [
                  {
                    text: 'Commission Models',
                    active: false,
                    to: 'commission-models',
                  },
                ],
              },
            },
          ],
        },
      ],
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/Login.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/oauth2-redirect',
      name: 'oauth2-redirect',
      component: () => import('@/views/pages/OAuth2Redirect.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },

    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const publicRoutes = ['login', 'clrgen', 'oauth2-redirect']
  if (to.matched.some(record => publicRoutes.includes(record.name) === false)) {
    const accessToken = useJwt.getToken()
    if (accessToken) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
