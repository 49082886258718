export const TEXT_ELLIPSIS = '...'
export const SYSTEM_SETTINGS = 'system-settings'
export const INDICATOR_ACTIONS = '__actions'
export const INDICATOR_FAV = '__fav--'

export const Schema = {
  CUSTOM: 'custom_schema',
  CUSTOM_RELATIONSHIP: 'custom_schema_relationship',
  CORE: 'core',
  CRM: 'crm',
  NOTES: 'notes',
  DOCUMENTS: 'documents',
}

export const Entity = {
  ACTIVITIES: 'activities',
  COMPANY: 'company',
  CONTACT: 'contact',
  DOCUMENT: 'document',
  NOTE: 'note',
  INQUIRY: 'inquiry',
  TODO: 'todo',
  COMMISSION: 'commission',
  MODEL: 'model',
  COMPANY_HAS_CONTACT: 'company_has_contact',
  NOTE_ATTACHED_TO: 'note_attached_to',
  INQUIRY_COMPANY_CONTACT: 'inquiry_company_contact',
  TODO_COMPANY_CONTACT: 'todo_company_contact',
}

export const EntityIcon = {
  'crm.company': 'BriefcaseIcon',
  'crm.contact': 'UsersIcon',
  'notes.note': 'ClipboardIcon',
  'core.inquiry': 'HelpCircleIcon',
  'core.todo': 'ListIcon',
}

export const GenericTabs = {
  OVERVIEW_360: '360-overview',
  ACTIVITIES: 'activities',
  COMPANIES: 'companies',
  CONTACTS: 'contacts',
  DOCUMENTS: 'documents',
  CONNECTIONS: 'connections',
  SIGNING_REQUESTS: 'signing-requests',
  SIGNICAT_SIGNING_REQUESTS: 'signicat-signing-requests',
  NOTES: 'notes',
  INQUIRIES: 'inquiries',
  TODO: 'todo',
  PREVIEW: 'preview',
  HISTORY: 'history',
  COMPANY_SETTINGS: 'company-settings',
  USER_SETTINGS: 'users-settings',
  GROUP_SETTINGS: 'groups-settings',
  ROLE_SETTINGS: 'roles-settings',
  DIGITAL_SIGNATURE_SETTINGS: 'digital-signature-settings',
  CUSTOM_FIELD_SETTINGS: 'custom-field-settings',
  CUSTOM_ENTITY_SETTINGS: 'custom-entity-settings',
  INQUIRY_SETTINGS: 'inquiries-settings',
  TODO_SETTINGS: 'todo-settings',
  STATUS_SETTINGS: 'status-settings',
  PRIORITY_SETTINGS: 'priority-settings',
  CATEGORY_SETTINGS: 'category-settings',
  SOURCE_SETTINGS: 'source-settings',
  INTEGRATIONS_SETTINGS: 'integrations-settings',
  DASHBOARD_WIDGET_SETTINGS: 'dashboard-widget',
  CRM_RELATIONSHIP_SETTINGS: 'crm-relationship-settings',
  EXPANDED_ENTITY_OVERVIEW: 'expanded-entity-overview-settings',
  FIELD_MANAGEMENT: 'field-management-settings',
  SHORTCUTS_SETTINGS: 'shortcuts-settings',
  COMMISSIONS: 'commissions',
  COMMISSION_MODELS: 'commission-models',
  MODEL: 'model',
  SALES: 'sales',
  SALES_CONTACTS: 'sales-contacts',
  SELLER: 'seller',
  HANDLER: 'handler',
  DASHBOARD: 'dashboard',
}

export const URL_USER_GUIDES = 'https://prozessno.atlassian.net/wiki/spaces/PKB/pages/2815361025/Start+her'

export const FeatherIcons = [
  'ActivityIcon',
  'AirplayIcon',
  'AlertCircleIcon',
  'AlertOctagonIcon',
  'AlertTriangleIcon',
  'AlignCenterIcon',
  'AlignJustifyIcon',
  'AlignLeftIcon',
  'AlignRightIcon',
  'AnchorIcon',
  'ApertureIcon',
  'ArchiveIcon',
  'ArrowDownCircleIcon',
  'ArrowDownLeftIcon',
  'ArrowDownRightIcon',
  'ArrowDownIcon',
  'ArrowLeftCircleIcon',
  'ArrowLeftIcon',
  'ArrowRightCircleIcon',
  'ArrowRightIcon',
  'ArrowUpCircleIcon',
  'ArrowUpLeftIcon',
  'ArrowUpRightIcon',
  'ArrowUpIcon',
  'AtSignIcon',
  'AwardIcon',
  'BarChart2Icon',
  'BarChartIcon',
  'BatteryChargingIcon',
  'BatteryIcon',
  'BellOffIcon',
  'BellIcon',
  'BluetoothIcon',
  'BoldIcon',
  'BookOpenIcon',
  'BookIcon',
  'BookmarkIcon',
  'BoxIcon',
  'BriefcaseIcon',
  'CalendarIcon',
  'CameraOffIcon',
  'CameraIcon',
  'CastIcon',
  'CheckCircleIcon',
  'CheckSquareIcon',
  'CheckIcon',
  'ChevronDownIcon',
  'ChevronLeftIcon',
  'ChevronRightIcon',
  'ChevronUpIcon',
  'ChevronsDownIcon',
  'ChevronsLeftIcon',
  'ChevronsRightIcon',
  'ChevronsUpIcon',
  'ChromeIcon',
  'CircleIcon',
  'ClipboardIcon',
  'ClockIcon',
  'CloudDrizzleIcon',
  'CloudLightningIcon',
  'CloudOffIcon',
  'CloudRainIcon',
  'CloudSnowIcon',
  'CloudIcon',
  'CodeIcon',
  'CodepenIcon',
  'CodesandboxIcon',
  'CoffeeIcon',
  'ColumnsIcon',
  'CommandIcon',
  'CompassIcon',
  'CopyIcon',
  'CornerDownLeftIcon',
  'CornerDownRightIcon',
  'CornerLeftDownIcon',
  'CornerLeftUpIcon',
  'CornerRightDownIcon',
  'CornerRightUpIcon',
  'CornerUpLeftIcon',
  'CornerUpRightIcon',
  'CpuIcon',
  'CreditCardIcon',
  'CropIcon',
  'CrosshairIcon',
  'DatabaseIcon',
  'DeleteIcon',
  'DiscIcon',
  'DivideCircleIcon',
  'DivideSquareIcon',
  'DivideIcon',
  'DollarSignIcon',
  'DownloadCloudIcon',
  'DownloadIcon',
  'DribbbleIcon',
  'DropletIcon',
  'Edit2Icon',
  'Edit3Icon',
  'EditIcon',
  'ExternalLinkIcon',
  'EyeOffIcon',
  'EyeIcon',
  'FacebookIcon',
  'FastForwardIcon',
  'FeatherIcon',
  'FigmaIcon',
  'FileMinusIcon',
  'FilePlusIcon',
  'FileTextIcon',
  'FileIcon',
  'FilmIcon',
  'FilterIcon',
  'FlagIcon',
  'FolderMinusIcon',
  'FolderPlusIcon',
  'FolderIcon',
  'FramerIcon',
  'FrownIcon',
  'GiftIcon',
  'GitBranchIcon',
  'GitCommitIcon',
  'GitMergeIcon',
  'GitPullRequestIcon',
  'GithubIcon',
  'GitlabIcon',
  'GlobeIcon',
  'GridIcon',
  'HardDriveIcon',
  'HashIcon',
  'HeadphonesIcon',
  'HeartIcon',
  'HelpCircleIcon',
  'HexagonIcon',
  'HomeIcon',
  'ImageIcon',
  'InboxIcon',
  'InfoIcon',
  'InstagramIcon',
  'ItalicIcon',
  'KeyIcon',
  'LayersIcon',
  'LayoutIcon',
  'LifeBuoyIcon',
  'Link2Icon',
  'LinkIcon',
  'LinkedinIcon',
  'ListIcon',
  'LoaderIcon',
  'LockIcon',
  'LogInIcon',
  'LogOutIcon',
  'MailIcon',
  'MapPinIcon',
  'MapIcon',
  'Maximize2Icon',
  'MaximizeIcon',
  'MehIcon',
  'MenuIcon',
  'MessageCircleIcon',
  'MessageSquareIcon',
  'MicOffIcon',
  'MicIcon',
  'Minimize2Icon',
  'MinimizeIcon',
  'MinusCircleIcon',
  'MinusSquareIcon',
  'MinusIcon',
  'MonitorIcon',
  'MoonIcon',
  'MoreHorizontalIcon',
  'MoreVerticalIcon',
  'MousePointerIcon',
  'MoveIcon',
  'MusicIcon',
  'Navigation2Icon',
  'NavigationIcon',
  'OctagonIcon',
  'PackageIcon',
  'PaperclipIcon',
  'PauseCircleIcon',
  'PauseIcon',
  'PenToolIcon',
  'PercentIcon',
  'PhoneCallIcon',
  'PhoneForwardedIcon',
  'PhoneIncomingIcon',
  'PhoneMissedIcon',
  'PhoneOffIcon',
  'PhoneOutgoingIcon',
  'PhoneIcon',
  'PieChartIcon',
  'PlayCircleIcon',
  'PlayIcon',
  'PlusCircleIcon',
  'PlusSquareIcon',
  'PlusIcon',
  'PocketIcon',
  'PowerIcon',
  'PrinterIcon',
  'RadioIcon',
  'RefreshCcwIcon',
  'RefreshCwIcon',
  'RepeatIcon',
  'RewindIcon',
  'RotateCcwIcon',
  'RotateCwIcon',
  'RssIcon',
  'SaveIcon',
  'ScissorsIcon',
  'SearchIcon',
  'SendIcon',
  'ServerIcon',
  'SettingsIcon',
  'Share2Icon',
  'ShareIcon',
  'ShieldOffIcon',
  'ShieldIcon',
  'ShoppingBagIcon',
  'ShoppingCartIcon',
  'ShuffleIcon',
  'SidebarIcon',
  'SkipBackIcon',
  'SkipForwardIcon',
  'SlackIcon',
  'SlashIcon',
  'SlidersIcon',
  'SmartphoneIcon',
  'SmileIcon',
  'SpeakerIcon',
  'SquareIcon',
  'StarIcon',
  'StopCircleIcon',
  'SunIcon',
  'SunriseIcon',
  'SunsetIcon',
  'TabletIcon',
  'TagIcon',
  'TargetIcon',
  'TerminalIcon',
  'ThermometerIcon',
  'ThumbsDownIcon',
  'ThumbsUpIcon',
  'ToggleLeftIcon',
  'ToggleRightIcon',
  'ToolIcon',
  'Trash2Icon',
  'TrashIcon',
  'TrelloIcon',
  'TrendingDownIcon',
  'TrendingUpIcon',
  'TriangleIcon',
  'TruckIcon',
  'TvIcon',
  'TwitchIcon',
  'TwitterIcon',
  'TypeIcon',
  'UmbrellaIcon',
  'UnderlineIcon',
  'UnlockIcon',
  'UploadCloudIcon',
  'UploadIcon',
  'UserCheckIcon',
  'UserMinusIcon',
  'UserPlusIcon',
  'UserXIcon',
  'UserIcon',
  'UsersIcon',
  'VideoOffIcon',
  'VideoIcon',
  'VoicemailIcon',
  'Volume1Icon',
  'Volume2Icon',
  'VolumeXIcon',
  'VolumeIcon',
  'WatchIcon',
  'WifiOffIcon',
  'WifiIcon',
  'WindIcon',
  'XCircleIcon',
  'XOctagonIcon',
  'XSquareIcon',
  'XIcon',
  'YoutubeIcon',
  'ZapOffIcon',
  'ZapIcon',
  'ZoomInIcon',
  'ZoomOutIcon',
]

export const languageOptions = [
  {
    key: 'en',
    label: 'English',
  },
  {
    key: 'no',
    label: 'Norwegian',
  },
]
